:root {
    --navy-blue-color: '#0e0e0e';
    --secondary-grey-color: '#757575';
}

* {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.calendar-custom-wrapper {
    position: absolute;
    top: 128px !important;
    z-index: 9999999 !important;
    height: 480px !important;
    background: #fff;
}

.dashboard-date-picker .react-calendar {
    max-width: 375px;
    background: var(--navy-blue-color);
    box-shadow: 3px 4px 69px rgb(0 0 0 / 50%);
    border: none;
    border-radius: 8px;
    padding: 28px 22px;
    width: auto;
}

.dashboard-date-picker .react-calendar {
    position: relative;
}

.dashboard-date-picker .react-calendar__navigation button {
    color: #000 !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
}

.dashboard-date-picker .react-calendar__navigation button:disabled {
    background: var(--navy-blue-color);
}

.dashboard-date-picker .react-calendar__navigation button:first-child,
.dashboard-date-picker .react-calendar__navigation button:last-child {
    display: none;
}

.dashboard-date-picker .search-calendar-btn {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 24px;
    bottom: 24px;
}

.dashboard-date-picker .search-calendar-btn::before {
    background-color: var(--navy-blue-color);
}

.dashboard-date-picker .react-calendar__navigation button:last-child::before {
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    border-radius: 4px;
    background-color: var(--navy-blue-color);
    z-index: -1;
}

.dashboard-date-picker .react-calendar__navigation button:last-child::after {
    content: '';
    font-size: 16px;
    background: linear-gradient(to left, #00ffa3, #000) !important;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(3) {
    flex-grow: inherit !important;
    pointer-events: none !important;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(2),
.dashboard-date-picker .react-calendar__navigation button:nth-child(4) {
    padding: 0px;
    width: 24px;
    border-radius: 50%;
    min-width: unset;
    position: absolute;
    top: 40px;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(2) {
    right: 48px;
    padding-right: 2px;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(4) {
    right: 22px;
    padding-left: 2px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent;
}

.react-calendar__navigation button:enabled:hover {
    background-color: #000 !important;
    color: white;
}

.dashboard-date-picker .react-calendar__month-view__weekdays {
    color: var(--secondary-grey-color);
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: capitalize;
    margin-top: 32px !important;
}

.dashboard-date-picker .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
}

.dashboard-date-picker .react-calendar__tile {
    border: none;
    color: var(--secondary-grey-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    padding: 8px;
    border-radius: 50%;
    margin-top: 8px;
}

.dashboard-date-picker .react-calendar__tile:disabled {
    background: var(--navy-blue-color);
    border-radius: 50%;
}

.dashboard-date-picker .react-calendar__tile--rangeStart,
.dashboard-date-picker .react-calendar__tile--rangeEnd,
.dashboard-date-picker .react-calendar__tile--hasActive,
.dashboard-date-picker .react-calendar__tile:enabled:hover,
.dashboard-date-picker .react-calendar__tile:enabled:focus {
    /* background: var(--secondary-neon-color) !important; */
    background-color: #000 !important;
    border-radius: 50%;
    color: white !important;
}

.dashboard-date-picker .react-calendar__tile--active,
.dashboard-date-picker .react-calendar--selectRange .react-calendar__tile--hover {
    background: #94c4f5 !important;
    color: black !important;
    border-radius: 50% !important;
}

.dashboard-date-picker .react-calendar__tile--now {
    background: transparent;
    border-radius: 50%;
}

.analytics-calendar-wrapper {
    position: relative;
}

.analytics-calendar-wrapper .calendar-custom-wrapper {
    top: 40px !important;
}

.MuiOutlinedInput-root {
    padding: unset !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h4kevy-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: unset !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h4kevy-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    padding-right: unset !important;
}

.MuiAutocomplete-hasPopupIcon.css-1h4kevy-MuiAutocomplete-root .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-1h4kevy-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: unset !important;
}

.MuiAutocomplete-hasPopupIcon.css-1h4kevy-MuiAutocomplete-root .MuiAutocomplete-inputRoot,
.MuiAutocomplete-hasClearIcon.css-1h4kevy-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    padding-right: unset !important;
}

.css-1h4kevy-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: unset !important;
}

.css-1h4kevy-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    flex-wrap: nowrap !important;
}

.css-15wabas-MuiInputBase-root-MuiOutlinedInput-root {
    padding-left: unset !important;
    padding-right: unset !important;
}

.MuiAutocomplete-option {
    /* Styles for each option in the Autocomplete */
    padding: 8px 16px !important;
    transition: all 0.2s ease-in-out !important;
}

/* Hover effect for Autocomplete options */

.MuiAutocomplete-option:hover {
    background-color: #e6e6e6 !important;
    cursor: pointer !important;
}

/* Sidebar Scrollbar */

.customScrollbar .ps__rail-y {
    width: 8px;
    background-color: #071736;
}

.customScrollbar .ps__thumb-y {
    width: 8px;
    background-color: #888;
}

.customScrollbar .ps__thumb-y:hover {
    width: 8px;
    background-color: #888;
}

.customScrollbar .ps__rail-y:hover {
    width: 8px;
    background-color: #071736;
}

.customScrollbar .ps__rail-y:not(:hover) {
    width: 8px;
    background-color: #071736;
}

.customScrollbar .ps__rail-x {
    height: 8px;
    background-color: #071736;
}

.customScrollbar .ps__thumb-x {
    height: 8px;
    background-color: #888;
}

.customScrollbar .ps__thumb-x:hover {
    height: 8px;
    background-color: #888;
}

.customScrollbar .ps__rail-x:hover {
    height: 8px;
    background-color: #071736;
}

.customScrollbar .ps__rail-x:not(:hover) {
    height: 8px;
    background-color: #071736;
}

/* Active Listings Scrollbar */

.customScrollbarActiveListings {
    position: relative;
}

.customScrollbarActiveListings .ps__rail-x {
    height: 16px;
    background-color: #d8d8d8;
}

.customScrollbarActiveListings .ps__thumb-x {
    height: 11px;
    background-color: #ffffff;
}

.customTooltipWrapper {
    /* width: 300px; */
    /* height: 220px; */
    background-color: #ffffff00;
    /* border: 2px solid #026CDF; */
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem;
    gap: 0.5rem;
}

.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
    display: none;
}

.apexcharts-legend {
    overflow: hidden !important;
}

.apexcharts-tooltip-title {
    display: flex;
    justify-content: center;
}

/* Chart */
.apexcharts-xaxistooltip,
.apexcharts-tooltip {
    border-radius: 15px !important;
}

#apexChartsavailableTickets {
    width: 10px !important;
}

/* Custom Input */
.custom-input {
    padding: 6px 0px 9.5px 15px !important;
}

.custom-date-picker {
    padding-block: 3px !important;
}

/* Slick arrows color */
.slick-prev:before,
.slick-next:before {
    color: #2a3041 !important;
}

/* Swiper arrows styles */
.custom-prev-button,
.custom-next-button {
    position: absolute;
    top: 50%;
    z-index: 1500;
    transform: translateY(-50%);
    cursor: pointer;
}

.custom-prev-button {
    left: 5rem;
}

.custom-next-button {
    right: 5rem;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    box-shadow: 0 0 0 1000px white inset;
}
